const mapElement = document.querySelector('#map');
let zoom = 13;
const addMarkersToMap = (focusPoint, mapContainer) => {
    const locations = document.querySelectorAll('.locations__entry');

    if(locations.length){
        locations.forEach(element => {
            const elementCoords = element.dataset.coord;

            if(elementCoords){
                L.marker(elementCoords.split(',')).addTo(mapContainer);
            }
        });

        mapContainer.setZoom(9)
        // mapContainer.eachLayer((layer) => {
        //     if(layer instanceof L.Marker){
        //         while(!mapContainer.getBounds().contains(layer.getLatLng())) {
        //             mapContainer.setZoom(zoom--)
        //         }
        //     }
        // });
    }
};

const initMapWithCurrentPosition = (position) => {
    const coords = [position.coords.latitude,position.coords.longitude];

    /**
     *
     * Create Map
     */
    const mapContainer = L.map(mapElement,{
        center: coords,
        zoom: zoom,
        removeOutsideVisibleBounds: true
    });

    /**
     * Disable Zoom by Scrollwheel
     */
    mapContainer.scrollWheelZoom.disable();

    /**
     * Create Map Tiles
     */
    L.tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | <a target=blank href="https://www.uwe-horn.net">uwe-horn.net</a>'
    }).addTo(mapContainer);

    /**
     * Add Marker to Map
     */
    const currentPosition = L.icon({
        iconUrl: 'https://www.dapa-hst.de/wp-content/themes/dapa/assets/svg/position.svg',
        iconSize: [40, 40]
    })
    L.marker(coords, {icon: currentPosition}).addTo(mapContainer);
    mapElement.parentElement.classList.remove('loading');
    addMarkersToMap(coords, mapContainer)
};

const initMapWithoutCurrentPosition = () => {
    const coords = [54.30235,13.011776];
    /**
     *
     * Create Map
     */
    const mapContainer = L.map(mapElement,{
        center: coords,
        zoom: zoom,
        removeOutsideVisibleBounds: true
    });

    /**
     * Disable Zoom by Scrollwheel
     */
    mapContainer.scrollWheelZoom.disable();

    /**
     * Create Map Tiles
     */
    L.tileLayer('https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors | <a target=blank href="https://www.uwe-horn.net">uwe-horn.net</a>'
    }).addTo(mapContainer);

    /**
     * Add Marker to Map
     */
    L.marker(coords).addTo(mapContainer);
    mapElement.parentElement.classList.remove('loading');
    addMarkersToMap(coords, mapContainer)
}

/**
 * Initialize Map
 */
const initMap = async () => {
    // const permission = await navigator.permissions.query({
    //     name: 'geolocation'
    // });

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(initMapWithCurrentPosition)
    } else {
        initMapWithoutCurrentPosition()
    }
}


/**
 * Check if Map Element exists on Document
 */
if(mapElement){
    initMap()
}

